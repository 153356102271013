<template>
  <div class="grid">
    <Toast :breakpoints="{'920px': {width: '100%', right: '0', left: '0'}}" />
    <div class="col-12">
      <div class="card">
        <p>
          <AutoComplete v-model="selectedMeigara" :suggestions="filteredMeigaras" 
          @complete="searchMeigara($event)" @item-select="onSearchClick(false)"
          optionLable="name" placeholder="銘柄コード or 銘柄名" 
          inputStyle="width: 350px"/>
          &nbsp;MeigarasearchExtController (mode0) {{perf0}}
        </p>
      </div>
      mode1 {{perf1}}<br>
      <DataTable :value="meigaras" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows selectionMode="single">
        <!-- <Column field="id" header="No"></Column> -->
        <Column field="meigara_cd" header="銘柄コード"></Column>        
        <Column field="meigara_name" header="銘柄名"></Column>
        <Column field="market_name" header="市場"></Column>
        <Column field="industry33_cd" header="業種コード"></Column>
        <Column field="industry33_name" header="業種区分"></Column>
        <Column field="kibo_cd" header="規模コード"></Column>
        <Column field="kibo_name" header="規模区分"></Column>  
        <Column field="meigara_name_en" header="銘柄名en"></Column>
        <Column field="meigara_url" header="銘柄URL"></Column>    
        <Column field="meigara_overview" header="銘柄概要"></Column>   
        <Column field="meigara_theme_list" header="銘柄テーマ"></Column> 
      </DataTable>
      <br>

      <div class="text-left ml-1 mt-2">
        <Accordion class="accordion-r">
          <AccordionTab header="R">
            <template v-for="(relation, index) in relations" :key="index">
              <div class="field-checkbox">
                <img class="trendchart-img" :src="'https://tradersr.com' + relation.trendchart_file" :alt="relation.trendchart_file"/>&nbsp;
                <Checkbox :inputId="relation.rcd" name="rela" :value="relation.rname" v-model="relas"/>
                <label :for="relation.rcd">{{relation.rtype}} / {{relation.rtypename}} / {{relation.rcd}} / {{relation.rname}} / {{relation.rdate}} {{relation.rtime}} / ({{relation.meigara_count}})</label>
              </div>
            </template>
          </AccordionTab>
        </Accordion>
			</div>
      <br>

      <Menu ref="menu1" :popup="true" :model="items"></Menu>
      <TabView lazy class="tabview-custom" @tab-click="onPeriodTabClick($event)">
        <template v-for="period in periods" :key="period.code">
          <TabPanel>
            <template #header>
              <span>{{period.name}}</span>
            </template>
            mode2 {{perf2}}<br>
            <DataTable ref="dt" :value="meigaras_price" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows selectionMode="single"
            :loading="loading2" dataKey="id">
            <!-- <DataTable class="p-datatable-sm"
              ref="dt" :value="meigaras_price" dataKey="id" 
              responsiveLayout="scroll"
              stripedRows >             -->
              <Column>
                <template #body="slotProps">
                  <Button icon="pi pi-ellipsis-v" class="p-button-secondary p-button-outlined h-2rem w-2rem" @click="toggle_setMeigaraCd($event, slotProps.data.meigara_cd, slotProps.data.meigara_name)"></Button>
                </template>
              </Column>
              <Column field="trendchart_file" header="トレンド">  
                <template #body="slotProps">
                  <router-link :to="{name:'meigarachart',params:{code:slotProps.data.meigara_cd}}">
                    <img class="trendchart-img" :src="'https://tradersr.com' + slotProps.data.trendchart_file" :alt="slotProps.data.trendchart_file"/>
                  </router-link>
                </template>
              </Column>                
              <Column field="meigara_name" header="銘柄名" :sortable="true">
                <template #body="slotProps">
                  <router-link :to="{name:'meigarasearch_ext',params:{code:slotProps.data.meigara_cd}}">{{slotProps.data.meigara_name}}</router-link>
                </template>
              </Column>  
              <Column field="meigara_cd" header="コード" :sortable="true">
                <template #body="slotProps">
                  {{slotProps.data.meigara_cd}}
                </template>
              </Column>
              <Column field="ago_today_diff" header="前日比" :sortable="true">
                <template #body="slotProps">
                  <div :class="priceClass(slotProps.data)">
                    {{slotProps.data.ago_today_diff}}
                  </div>
                </template>
              </Column>
              <Column field="ago_today_ratio" header="前日比%" :sortable="true">
                <template #body="slotProps">
                  <div :class="priceClass(slotProps.data)">
                    {{slotProps.data.ago_today_ratio}}
                  </div>
                </template>        
              </Column>  
              <Column field="settlement_date" header="settlement_date" :sortable="true"></Column>   
              <Column field="rprice" header="相対株価" :sortable="true"></Column>      
              <Column field="depth" header="depth" :sortable="true"></Column>    
              <Column field="price_date" header="日付" :sortable="true"></Column>                    
              <Column field="price_timestamp" header="時刻" :sortable="true"></Column>                 
              <Column field="high" header="高値" :sortable="true"></Column>
              <Column field="low" header="安値" :sortable="true"></Column>
              <Column field="open" header="始値" :sortable="true"></Column>               
              <Column field="close" header="終値" :sortable="true"> 
              </Column>              
              <Column field="volume" header="出来高" :sortable="true"></Column>
              <!-- <Column field="adj_close" header="調整後終値" :sortable="true"></Column> -->         
              <Column field="volume_ratio" header="出来高前日比%" :sortable="true"></Column>
              <Column field="info_source" header="情報源" :sortable="true"></Column>
              <Column field="per" header="per" :sortable="true"></Column>
              <Column field="pbr" header="pbr" :sortable="true"></Column>
              <Column field="yield" header="yield" :sortable="true"></Column>
              <Column field="margin_ratio" header="margin_ratio" :sortable="true"></Column>
              <Column field="market_value_str" header="market_value_str" :sortable="true"></Column>
              <Column field="rela_meigara_cd_list" header="rela_meigara_cd_list" :sortable="true"></Column>
              <Column field="rela_meigara_name_list" header="rela_meigara_name_list" :sortable="true"></Column>
              <Column field="rela_at_ratio" header="rela_at_ratio" :sortable="true"></Column>
              <Column field="rstrength" header="rstrength" :sortable="true"></Column>
              <Column field="cont_class" header="cont_class" :sortable="true"></Column>
              <Column field="taishaku_kubun" header="taishaku_kubun" :sortable="true"></Column>
              <Column field="market_name" header="market_name" :sortable="true"></Column>
              <Column field="market_at_ratio" header="market_at_ratio" :sortable="true"></Column>
              <Column field="mrstrength" header="mrstrength" :sortable="true"></Column>
              <Column field="ma5" header="ma5" :sortable="true"></Column>
              <Column field="ma5_dratio" header="ma5_dratio" :sortable="true"></Column>
              <Column field="ma10" header="ma10" :sortable="true"></Column>
              <Column field="ma10_dratio" header="ma10_dratio" :sortable="true"></Column>
              <Column field="ma25" header="ma25" :sortable="true"></Column>
              <Column field="ma25_dratio" header="ma25_dratio" :sortable="true"></Column>
              <Column field="ma50" header="ma50" :sortable="true"></Column>
              <Column field="ma50_dratio" header="ma50_dratio" :sortable="true"></Column>
              <Column field="ma75" header="ma75" :sortable="true"></Column>
              <Column field="ma75_dratio" header="ma75_dratio" :sortable="true"></Column>
              <Column field="gap_ratio" header="gap_ratio" :sortable="true"></Column>
              <Column field="vola_ratio" header="vola_ratio" :sortable="true"></Column>
            </DataTable>
            <br>mode3 {{perf3}}<br>
            <DataTable :value="meigaras_simi" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows selectionMode="single"
            :loading="loading3">
              <Column field="similarity_a" header="類似性a(デフォルト)" :sortable="true"></Column>   
              <Column field="similarity_b" header="類似性b(相対株価)" :sortable="true"></Column>                  
              <Column field="meigara_cd" header="銘柄コード" :sortable="true"></Column>
              <Column field="meigara_name" header="銘柄名" :sortable="true"></Column>
              <Column field="depth" header="depth" :sortable="true"></Column>
              <Column field="industry33_cd" header="業種コード" :sortable="true"></Column> 
              <Column field="industry33_name" header="業種区分" :sortable="true"></Column>
              <Column field="market_name" header="市場" :sortable="true"></Column>
              <Column field="kibo_cd" header="規模コード" :sortable="true"></Column>
              <!-- <Column field="meigara_theme_list" header="銘柄テーマ" :sortable="true"></Column> -->
              <Column field="market_value_class" header="時価総額クラス" :sortable="true"></Column>
              <Column field="yield_class" header="利回りクラス" :sortable="true"></Column>
              <Column field="per_class" header="PERクラス" :sortable="true"></Column>
              <Column field="pbr_class" header="PBRクラス" :sortable="true"></Column>
              <Column field="rprice" header="相対株価" :sortable="true"></Column>              
              <Column field="mvalue_ridx" header="時価総額_ridx" :sortable="true"></Column>        
              <Column field="yield_ridx" header="利回り_ridx" :sortable="true"></Column>
              <Column field="per_ridx" header="PER_ridx" :sortable="true"></Column>
              <Column field="pbr_ridx" header="PBR_ridx" :sortable="true"></Column>
              <Column field="rprice_idx" header="相対株価_idx" :sortable="true"></Column>
            </DataTable>      
            <br>mode4 {{perf4}}<br>
            <DataTable :value="meigaras_corr" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows selectionMode="single"
            :loading="loading4">
              <Column field="corr_atratio_c" header="相関性(c)" :sortable="true"></Column>
              <Column field="meigara_cd" header="銘柄コード" :sortable="true"></Column>
              <Column field="meigara_name" header="銘柄名" :sortable="true"></Column>
              <Column field="depth" header="depth" :sortable="true"></Column> 
              <Column field="corr_atratio" header="corr_atratio" :sortable="true"></Column>
              <Column field="corr_volume" header="corr_volume" :sortable="true"></Column>
              <Column field="atratio_c_avg" header="atratio_c_avg" :sortable="true"></Column>
              <Column field="atratio_c_max" header="atratio_c_max" :sortable="true"></Column>
              <Column field="atratio_c_min" header="atratio_c_min" :sortable="true"></Column>
              <Column field="atratio_avg" header="atratio_avg" :sortable="true"></Column>
              <Column field="atratio_max" header="atratio_max" :sortable="true"></Column>
              <Column field="atratio_min" header="atratio_min" :sortable="true"></Column>        
              <Column field="volume_avg" header="volume_avg" :sortable="true"></Column>
              <Column field="volume_max" header="volume_max" :sortable="true"></Column>
              <Column field="volume_min" header="volume_min" :sortable="true"></Column>
            </DataTable>
          </TabPanel>
        </template>
      </TabView>
    </div>
  </div>
</template>

<script>
import MeigarasearchExtService from '@/service/MeigarasearchExtService';
// import {FilterService,FilterMatchMode} from 'primevue/api';
import BookmarksCrudService from '@/service/BookmarksCrudService';
import axios from 'axios';

export default {
  data() {
    return {
      messages: [],
      relas: [],
      perf0: null,
      perf1: null,
      perf2: null,
      perf3: null,      
      perf4: null,  
      loading2: false,       
      loading3: false,       
      loading4: false,      
      meigaraKey: null,
      currentMeigara: null,
      meigaras: null,
      relations: null,
      meigaras_price: null,
      meigaras_simi: null,      
      meigaras_corr: null,
      selectedMeigara: null,
      selectedMeigaraCd: null,
      selectedMeigaraName: null,
      // selectedBookmarkCd: null,
      filteredMeigaras: null,
      //currentTabIndex: null,     
      currentPeriodID: 0,    
      bookmark: {},
      periods: [
        {name: '25日', code: '25d'},
        {name: '5日', code: '5d'},
        {name: '10日', code: '10d'},
        {name: '50日', code: '50d'},
        {name: '75日', code: '75d'},
        {name: '6ヶ月', code: '6m'},
        {name: '1年', code: '1y'},
      ],      
      items: [
        {
          label: '銘柄をブックマーク',
          command: () => {
            // this.$router.push('/meigarachart/' + this.selectedMeigaraCd);

            let param_bookmark = {
              userid: 'tradersr',
              bookmark_type: 'M',
              bookmark_group: null,
              bookmark_cd: this.selectedMeigaraCd,
              bookmark_name_ja: this.selectedMeigaraName,
              bookmark_name_en: null,
              bookmark_desc: null,
              public_flag: null,
              public_category: null,
              u_date: null,
              passcode: 'Admin1234!!!!'
            };

            axios.post('https://api.tradersr.com/api/bookmarkscrud', param_bookmark)
            .then(response => {
              console.log(response);
              if(response.data == 'existing'){
                this.$toast.add({severity:'warn', summary: 'ブックマーク', detail:this.selectedMeigaraCd + ' ' + this.selectedMeigaraName +' は既に登録されています', life: 2000});
              }else{
                this.$toast.add({severity:'success', summary: 'ブックマーク', detail:this.selectedMeigaraCd + ' ' + this.selectedMeigaraName +' をブックマークしました', life: 2000});
              }
              // this.bookmark = response.data.data;
              // if(this.bookmark){
              //   this.bookmarks.push(this.bookmark);
              // }
            })
            .catch(e => {
              console.log(e);
              this.$toast.add({severity:'error', summary: 'ブックマーク',detail:e, life: 2000});
            })

            // this.$toast.add({severity:'success', summary: 'ブックマークを追加', detail:this.selectedMeigaraCd + ' ' + this.selectedMeigaraName +' をブックマークしました。', life: 3000});
            // this.$toast.add({severity:'info', summary: 'Info Message', detail:'Message Content', life: 3000});
            // this.$toast.add({severity:'warn', summary: 'Warn Message', detail:'Message Content', life: 3000});
            // this.$toast.add({severity:'error', summary: 'Error Message', detail:'Message Content', life: 3000});
					}
        },
        // {
        //   label: 'ブックマークを削除',
        //   command: () => {
        //     axios.delete('https://api.tradersr.com/api/bookmarkscrud/' + this.selectedMeigaraCd)
        //       .then(response => {
        //         console.log(response);
        //         this.$toast.add({severity:'success', summary: 'ブックマークを削除',detail:this.selectedMeigaraCd + ' ' + this.selectedMeigaraName +' を削除しました。', life: 3000});
        //       })
        //       .catch(e => {
        //         // alert(e);
        //         this.$toast.add({severity:'error', summary: 'ブックマークを削除',detail:e, life: 3000});
        //       })

        //     // this.$toast.add({severity:'success', summary: 'ブックマーク削除',detail:this.selectedMeigaraCd + ' ' + this.selectedMeigaraName +' を削除しました。', life: 3000});
        //     // this.$toast.add({severity:'info', summary: 'Info Message', detail:'Message Content', life: 3000});
        //     // this.$toast.add({severity:'warn', summary: 'Warn Message', detail:'Message Content', life: 3000});
        //     // this.$toast.add({severity:'error', summary: 'Error Message', detail:'Message Content', life: 3000});
				// 	}
        // },
        {
          label: '銘柄拡張検索',
          command: () => {
            this.$router.push('/meigarasearch_ext/' + this.selectedMeigaraCd);
					}
        },
        {
          label: '銘柄ニュース',
          command: () => {
            this.$router.push('/meigaranewsrecentl/gridm' + this.selectedMeigaraCd);
					}
        },
        {
          label: '銘柄チャート',
          command: () => {
            this.$router.push('/meigarachart/' + this.selectedMeigaraCd);
					}
        },
      ],      
    }
  },
  meigarsearchService: null,
  bookmarksCrudService: null,
  created() {
    this.meigarasearchExtService = new MeigarasearchExtService();
    this.bookmarksCrudService = new BookmarksCrudService();
  },
  mounted() {
    this.selectedMeigara = this.$route.params.code;
    //alert(this.selectedMeigara);
    if(this.selectedMeigara.length >= 4){
      this.currentMeigara = this.selectedMeigara;
      this.onSearchClick(false);
      this.getRelations();
    }
    this.selectedMeigara = null;
  },
  // watch: {
  //   $route() {
  //     location.reload();
  //   }
  // },
  methods: {
      priceClass(data) {
      return [
        {
          'positive': data.ago_today_diff > 0,
          'negative': data.ago_today_diff < 0,
          'neutral': data.ago_today_diff == 0,
        }
      ];
    },  
    searchMeigara() {
      let filteredMeigaras = [];
      this.meigarasearchExtService.getMeigaraInfo(this.selectedMeigara, 0, null)
        .then(d => {
          for(let i=0; i<d.data.length; i++){
            let item = d.data[i].name;
            filteredMeigaras.push(item);
          }
          this.filteredMeigaras = filteredMeigaras;
          this.perf0 = d.perf;

          this.currentMeigara = this.selectedMeigara;
        }
      );    
    },
    onPeriodTabClick(event){
      if (this.currentPeriodID != event.index) {   
        this.currentPeriodID = event.index; 
        this.onSearchClick(true);
      }
    },
    getRelations(){
      this.meigarasearchExtService.getRelations(this.currentMeigara)
            .then(d => {
              this.relations = d.data;
              // this.perf1 = d.perf;
              // console.log(this.currentMeigara);
            }
          );
    },
    onSearchClick(isTabClick) {
      let period = this.periods[this.currentPeriodID].code;

      if(this.currentMeigara.length >= 4){
        let meigara_cd = this.currentMeigara.substr(0,4);

        if(!isTabClick) {
          this.meigarasearchExtService.getMeigaraInfo(meigara_cd, 1, null)
            .then(d => {
              this.meigaras = d.data;
              this.perf1 = d.perf;
            }
          );
        }

        this.loading2 = true;
        this.meigarasearchExtService.getMeigaraInfo(meigara_cd, 2, period)
          .then(d => {
            this.meigaras_price = d.data;
            this.perf2 = d.perf;
            this.loading2 = false;
          }
        );         

        this.loading3 = true;
        this.meigarasearchExtService.getMeigaraInfo(meigara_cd, 3, period)
          .then(d => {
            this.meigaras_simi = d.data;
            this.perf3 = d.perf;
            this.loading3 = false;
          }
        ); 

        this.loading4 = true;
        this.meigarasearchExtService.getMeigaraInfo(meigara_cd, 4, period)
          .then(d => {            
            this.meigaras_corr = d.data;
            this.perf4 = d.perf;
            this.loading4 = false; 
          }
        );

        this.selectedMeigara = null;

        //alert('called');
      }  
    },
    toggle_setMeigaraCd(event, mcd, mname){
      this.$refs.menu1.toggle(event);
      this.selectedMeigaraCd = mcd;
      this.selectedMeigaraName = mname;
    },
    gotoMeigaraChart(code){
      this.$router.push('/meigarachart/' + code);
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/prices.scss';

.trendchart-img {
  opacity: 0.8;
  border: solid 1px #cccccc;
  border-radius: 5px;  
  width: 136px;
  height: 35px;
}
</style>